<template>

  <div class="homeIndexF">
    <div class="homeIndex">
      <HomeHeader></HomeHeader>
      <div id="home" :style="{ height: innerHeight }">
        <div class="flex-center">
          <div class="content">
            <div class="nav">
              <ul class="first-nav">
                <li>
                  <div
                      @click="jumpPage('eios/companyInfo', 'Eios控制面板')"
                      class="medium-nav control-panel"
                      :class="hasMenuPermission('Eios控制面板') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('Eios控制面板')"
                        src="../../assets/images/home/control-panel.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/control-panel-disabled.png"
                        alt=""
                    />
                    <span class="medium-text">系统配置</span>
                  </div>
                </li>
                <li>
                  <div
                      class="medium-nav device"
                      @click="Jump('Administration', '资产管理')"
                      :class="hasMenuPermission('资产管理') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('资产管理')"
                        src="../../assets/images/home/device.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/device-disabled.png"
                        alt=""
                    />
                    <span class="medium-text">设备管理</span>
                  </div>
                </li>
                <li class="flex-horizontal-between">
                  <div
                      class="mini-nav analysis"
                      @click="Jump1('StatisticsAnalysis', '统计分析')"
                      :class="hasMenuPermission('统计分析') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('统计分析')"
                        src="../../assets/images/home/energy.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/energy-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">用能计划</span>
                  </div>
                  <!--                  @click="Jump('inspectionPlan', '碳排分析')"-->
                  <!--                  :class="hasMenuPermission('碳排分析') ? '' : 'disabled'"-->

                  <div
                      class="mini-nav plan disabled"
                  >
                    <!--                    <img-->
                    <!--                      v-if="hasMenuPermission('碳排分析')"-->
                    <!--                      src="../../assets/images/home/plan.png"-->
                    <!--                      alt=""-->
                    <!--                    />-->
                    <img
                        src="../../assets/images/home/plan-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">碳排分析</span>
                  </div>
                </li>

                <li class="flex-horizontal-between">
                  <div
                      class="mini-nav aily-work"
                      @click="Jump('Document', '日常办公')"
                      :class="hasMenuPermission('日常办公') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('日常办公')"
                        src="../../assets/images/home/daily-work.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/daily-work-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">日常办公</span>
                  </div>
                  <div
                      class="mini-nav knowledge"
                      @click="Jump('Library', '知识库')"
                      :class="hasMenuPermission('知识库') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('知识库')"
                        src="../../assets/images/home/knowledge.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/knowledge-disabled.png"
                        alt=""
                    />
                    <span class="mini-text" style="left: 0.38rem">知识库</span>
                  </div>
                </li>
              </ul>
              <ul class="second-nav">
                <li>
                  <div
                      class="big-nav monitor"
                      @click="jumpPage('monitoringLeft', '综合监控')"
                      :class="hasMenuPermission('综合监控') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('综合监控')"
                        src="../../assets/images/home/monitor.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/monitor-disabled.png"
                        alt=""
                    />
                    <span class="big-text">综合监控</span>
                  </div>
                </li>
                <li class="flex-horizontal-between">
                  <div
                      class="mini-nav operation"
                      @click="
                      jumpPage('operationManagement/inspectionTask', '运维管理')
                    "
                      :class="hasMenuPermission('运维管理') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('运维管理')"
                        src="../../assets/images/home/operation.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/operation-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">运维管理</span>
                  </div>
                  <div
                      class="mini-nav examine"
                      @click="Jump('Management', '考核管理')"
                      :class="hasMenuPermission('考核管理') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('考核管理')"
                        src="../../assets/images/home/examine.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/examine-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">考核管理</span>
                  </div>
                </li>
                <li class="flex-horizontal-between">
                  <div
                      class="mini-nav secure"
                      @click="Jump('inspectionPlan', '计划管理')"
                      :class="hasMenuPermission('计划管理') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('计划管理')"
                        src="../../assets/images/home/plan.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/plan-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">计划管理</span>
                  </div>
                  <div
                      class="mini-nav energy"
                      @click="Jump('Control', '安全管理')"
                      :class="hasMenuPermission('安全管理') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('安全管理')"
                        src="../../assets/images/home/secure.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/secure-disabled.png"
                        alt=""
                    />
                    <span class="mini-text">安全管理</span>
                  </div>
                </li>

              </ul>
              <ul class="third-nav">
                <li>
                  <div
                      class="small-nav electricity"
                      @click="jumpPage('electricityHome', '驾驶舱-源')"
                      :class="hasMenuPermission('驾驶舱-源') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('驾驶舱-源')"
                        src="../../assets/images/home/electricity.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/electricity-disabled.png"
                        alt=""
                    />
                    <span style="color: #3bebbc" class="small-text"
                    >驾驶舱-源</span
                    >
                  </div>
                </li>
                <li>
                  <div
                      class="small-nav water"
                      @click="jumpPage('electricityNetwork', '驾驶舱-网')"
                      :class="hasMenuPermission('驾驶舱-网') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('驾驶舱-网')"
                        src="../../assets/images/home/water.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/water-disabled.png"
                        alt=""
                    />
                    <span style="color: #4c93fd" class="small-text"
                    >驾驶舱-网</span
                    >
                  </div>
                </li>
                <li>
                  <div
                      class="small-nav gas"
                      @click="jumpPage('electricityLotus', '驾驶舱-荷')"
                      :class="hasMenuPermission('驾驶舱-荷') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('驾驶舱-荷')"
                        src="../../assets/images/home/gas.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/gas-disabled.png"
                        alt=""
                    />
                    <span style="color: #fbd072" class="small-text"
                    >驾驶舱-荷</span
                    >
                  </div>
                </li>
                <li>
                  <div
                      class="small-nav heating"
                      @click="jumpPage('electricityStore', '驾驶舱-储')"
                      :class="hasMenuPermission('驾驶舱-储') ? '' : 'disabled'"
                  >
                    <img
                        v-if="hasMenuPermission('驾驶舱-储')"
                        src="../../assets/images/home/heating.png"
                        alt=""
                    />
                    <img
                        v-else
                        src="../../assets/images/home/heating-disabled.png"
                        alt=""
                    />
                    <span style="color: #eb4646;" class="small-text">
                      驾驶舱-储
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="chart">
              <div style="margin-bottom: 22px" class="electricity-chart">
                <span class="chart-title">总用电量情况统计</span>
                <div class="energy-operate">
                  <div class="all-energy">
                    <div
                        v-for="(item, index) in chartData"
                        :key="index"
                        @click="switchType(index)"
                    >
                      <span
                          :class="index === selectType ? 'selected' : ''"
                          class="energy-type"
                      >{{ item }}</span
                      >
                    </div>
                  </div>
                </div>
                <div id="chartSource" v-show="nvaShow"></div>
                <div id="chartNetwork" v-show="nvaShow1"></div>
                <div id="chartLotus" v-show="nvaShow2"></div>
                <div id="chartStore" v-show="nvaShow3"></div>
              </div>
              <div class="carbon-chart">
                <span class="chart-title">运维情况统计</span>
                <div class="boxs">
                  <div class="list"><img src="../../assets/images/home/aram.png" alt="">
                    <div class="right"><p style="color:#e02727;">{{ '4' }}</p>
                      <p>告警</p></div>
                  </div>
                  <div class="list"><img src="../../assets/images/home/renwu.png" alt="">
                    <div class="right"><p style="color:#ee7f40;">{{ '12' }}</p>
                      <p>任务</p></div>
                  </div>
                  <div class="list"><img src="../../assets/images/home/jihuan.png" alt="">
                    <div class="right"><p style="color:#1bcda0;">{{ '6' }}</p>
                      <p>计划</p></div>
                  </div>
                  <div class="list"><img src="../../assets/images/home/xiaoqu.png" alt="">
                    <div class="right"><p style="color:#a38961;">{{ '8' }}</p>
                      <p>消缺</p></div>
                  </div>

                </div>
                <div class="carbon-operate">

                  <ul>
                    <li class="consume">
                      <img src="../../assets/images/home/CO2.png" alt=""/>
                      <div>
                        <p>{{ totalCo2 }}</p>
                        <span>二氧化碳排放量</span>
                      </div>
                    </li>
                    <li class="consume" style="margin-right: 0;">
                      <img src="../../assets/images/home/fell.png" alt=""/>
                      <div>
                        <p class="tree">{{ equivalentTreePlanting }}</p>
                        <span>等效植树</span>
                      </div>
                    </li>
                    <li style="margin-right: 0" class="consume">
                      <img src="../../assets/images/home/burn.png" alt=""/>
                      <div>
                        <p>0</p>
                        <span>燃烧标准煤</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <div id="carbon"></div> -->
              </div>
            </div>
          </div>
          <!-- <div class="mascot">
            <img src="../../assets/images/home/mascot.png" alt="" />
          </div> -->
        </div>
      </div>
      <HomeFooter></HomeFooter>
    </div>
  </div>
</template>
<script>
import HomeHeader from "../../components/homeHeader.vue";
import HomeFooter from "../../components/footer.vue";
import {monitorApi} from "@/api/monitor.js";
import * as echarts from "echarts";
import axios from "axios";

export default {
  components: {HomeHeader, HomeFooter},
  data() {
    return {
      token: null,
      chartData: ["源", "网", "荷", "储"],
      selectType: 0,
      innerHeight: "",
      menuList: [],
      totalCo2: '',
      equivalentTreePlanting: '',
      allPermission: true,
      nvaShow: true,
      nvaShow1: false,
      nvaShow2: false,
      nvaShow3: false,
      sourceList: [],
      networkList: [],
      lotusList: [],
      storeList: [],
    };
  },
  created() {
    this.$store.commit("increment", "首页");
    this.$store.commit("selectChild", "");
    this.$store.commit("selectChildren", "");
    this.getSourceEchart()
    this.getNetWorkEchart()
    this.getLotusEchart()
    this.getStoreEchart()
  },
  mounted() {
    this.queryTotalCarbon();
    this.queryTotalElectricity();
    // this.getsso()
    // sessionStorage.setItem('first_menu','首页')
    let innerHeight = window.innerHeight - 121;
    this.innerHeight = `${innerHeight}px`;
    this.menuList = this.$store.state.menuList.map((item) => {
      if (!item.hidden) {
        return item.menuName;
      }
    });
    console.log("~~~~``", this.menuList);
  },
  computed: {
    hasMenuPermission() {
      console.log(this.menuList.includes(name), '123123')
      return name => {
        return this.menuList.includes(name) || this.allPermission
      }
    }
  },
  methods: {
    getsso() {
      let params = {
        userName: JSON.parse(sessionStorage.getItem('user_info')).userName
      }
      monitorApi.sso(params).then(res => {

      })
    },
    Jump(el, name) {
      if (this.menuList.includes(name)) {
        // sessionStorage.setItem('first_menu',name)
        this.$store.commit("increment", name);
        this.$router.push({
          name: el,
        });
      } else {
        this.$message({
          message: "您没有查看该菜单的权限",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    Jump1(el, name) {
      if (this.menuList.includes(name)) {
        this.token = sessionStorage.getItem('token')
        // let data={token:this.token};
        monitorApi.busDrivingSchool().then((res) => {
          // console.log("111",res);
          const token = this.token;
          // // 系统B的基础URL
          const systemBUrl = res.data.url;
          // const systemBUrl = 'http://localhost:8080/#/loginDemand';

          // // 添加查询参数

          const queryString = `?token=${token}`;
          const newSystemUrl = `${systemBUrl}${queryString}`
          console.log("1111", newSystemUrl);
          window.open(newSystemUrl, '_blank');
        });
        // sessionStorage.setItem('first_menu',name)
        // this.$store.commit("increment", name);
        // this.$router.push({
        //   name: el,
        // });


      } else {
        this.$message({
          message: "您没有查看该菜单的权限",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    switchType(index) {
      this.selectType = index;
      if (index == '0') {
        this.nvaShow = true
        this.nvaShow1 = false
        this.nvaShow2 = false
        this.nvaShow3 = false
        this.getChartSource(this.sourceList)
      } else if (index == '1') {
        this.nvaShow = false
        this.nvaShow1 = true
        this.nvaShow2 = false
        this.nvaShow3 = false
        this.$nextTick(() => {
          this.getChartNetwork(this.networkList)
        })
      } else if (index == '2') {
        this.nvaShow = false
        this.nvaShow1 = false
        this.nvaShow2 = true
        this.nvaShow3 = false
        this.$nextTick(() => {
          this.getChartLotus(this.lotusList)
        })

      } else if (index == '3') {
        this.nvaShow = false
        this.nvaShow1 = false
        this.nvaShow2 = false
        this.nvaShow3 = true
        this.getChartStore(this.storeList)
      }
    },
    //源柱图获取
    getSourceEchart() {
      monitorApi.getSource().then(res => {
        if (res.code == '200') {
          this.sourceList = res.data
          this.getChartSource(this.sourceList)
        }
      })
    },
    getChartSource(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY.push(Number(item.energy / 1000).toFixed(2))
      })
      var option = {
        tooltip: {},
        legend: {
          icon: "circle",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left: 80,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            width: 24,
            interval: 0,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 9,
            },
            formatter: function (value) {
              return Number(value) > 10 ? value + ':00' : '0' + value + ':00'
            }
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "发电量:kW",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#A0C6EF",
            },
          },
        },
        series: [
          {
            name: "发电量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1ACD9E" },
                { offset: 1, color: "#45F3C6" },
              ]),
              barBorderRadius:[30,30,0,0] ,
            },
            data: arrY,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("chartSource")).setOption(option,true);
    },

    //网柱图获取
    getNetWorkEchart() {
      monitorApi.getNetwork().then(res => {
        if (res.code == '200') {
          this.networkList = res.data
        }
      })
    },
    getChartNetwork(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY.push(Number(item.energy / 1000).toFixed(2))
      })

      var option = {
        tooltip: {},
        legend: {
          icon: "circle",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left: 80,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            width: 24,
            interval: 0,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 9,
            },
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "用电量:kWh",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#A0C6EF",
            },
          },
        },
        series: [
          {
            name: "总用电量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1ACD9E" },
                { offset: 1, color: "#45F3C6" },
              ]),
              barBorderRadius:[30,30,0,0] ,
            },
            data: arrY,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("chartNetwork")).setOption(option);
    },

    //荷柱图获取
    getLotusEchart() {
      monitorApi.getLotus().then(res => {
        if (res.code == '200') {
          this.lotusList = res.data
        }
      })
    },
    getChartLotus(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY.push(Number(item.energy / 1000).toFixed(2))
      })
      var option = {
        tooltip: {},
        legend: {
          icon: "circle",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left: 80,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            width: 24,
            interval: 0,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 9,
            },
            formatter: function (value) {
              return Number(value) > 10 ? value + ':00' : '0' + value + ':00'
            }
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "用电量:kWh",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#A0C6EF",
            },
          },
        },
        series: [
          {
            name: "总用电量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1ACD9E" },
                { offset: 1, color: "#45F3C6" },
              ]),
              barBorderRadius:[30,30,0,0] ,
            },
            data: arrY,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("chartLotus")).setOption(option);
    },

    //储柱图获取
    getStoreEchart() {
      monitorApi.getStore().then(res => {
        if (res.code == '200') {
          this.storeList = res.data
        }
      })
    },
    getChartStore(data) {
      // 指定图表的配置项和数据
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.hour)
        arrY.push(Number(item.chargeEnergy / 1000).toFixed(2))
      })
      var option = {
        tooltip: {},
        legend: {
          icon: "circle",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left: 80,
          right: 60,
        },
        xAxis: {
          data: arrX,
          axisLabel: {
            width: 24,
            interval: 0,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 9,
            },
            formatter: function (value) {
              return Number(value) > 10 ? value + ':00' : '0' + value + ':00'
            }
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "充电量:kW",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#A0C6EF",
            },
          },
        },
        series: [
          {
            name: "充电量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1ACD9E" },
                { offset: 1, color: "#45F3C6" },
              ]),
              barBorderRadius:[30,30,0,0] ,
            },
            data: arrY,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("chartStore")).setOption(option);
    },

    drawEnergy(xAxisData, seriesData) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          icon: "circle",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        grid: {
          left: 90,
          right: 60,
        },
        xAxis: {
          data: xAxisData,
          axisLabel: {
            width: 24,
            interval: 0,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "用电量:MWh",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#A0C6EF",
            },
          },
        },
        series: [
          {
            name: "总用电量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1ACD9E" },
                { offset: 1, color: "#45F3C6" },
              ]),
              barBorderRadius:[30,30,0,0] ,
            },
            data: seriesData,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("energy")).setOption(option);
    },


    jumpPage(path, name) {
      console.log(name, '3331131')
      if (name === "Eios控制面板") {
        sessionStorage.setItem("componentStatus", 0);
        this.$store.commit("updateStatus", 0);
      } else if (name === "驾驶舱-网") {
        sessionStorage.setItem("componentStatus", 1);
        this.$store.commit("updateStatus", 1);
      }
      if (this.menuList.includes(name)) {
        // sessionStorage.setItem('first_menu',name)
        this.$store.commit("increment", name);
        this.$router.push(`/${path}`);
      } else {
        this.$router.push(`/${path}`);
      }

      // else {
      //   this.$message({
      //     message: "您没有查看该菜单的权限",
      //     type: "warning",
      //     duration: 3000,
      //     customClass: "messageText",
      //   });
      // }
    },
    // 1.总用电量情况统计
    queryTotalElectricity() {
      monitorApi.queryTotalElectricity().then((res) => {
        let object = res[0];
        let xAxisData = [];
        let seriesData = [];
        for (const key in object) {
          let time = Number(key.slice(0, 2));
          xAxisData.push(`${time}点`);
          if (object[key]) {
            seriesData.push(object[key]);
          } else {
            seriesData.push(`0`);
          }
        }
        this.drawEnergy(xAxisData, seriesData);
      });
    },
    // 2.碳排情况统计
    queryTotalCarbon() {
      monitorApi.queryTotalCarbon().then((res) => {
        if (res.code === 200) {
          this.totalCo2 = res.data.totalCo2.toFixed(3)
          this.equivalentTreePlanting = res.data.equivalentTreePlanting.toFixed(3)
          let object = res.data.maps[0];
          let xAxisData = [];
          let seriesData = [];
          for (const key in object) {
            let time = Number(key.slice(0, 2));
            xAxisData.push(`${time}点`);
            if (object[key]) {
              seriesData.push(object[key].toFixed(3));
            } else {
              seriesData.push(`0`);
            }
          }
          this.drawCarbon(xAxisData, seriesData);
        }

      });
    },
    drawCarbon(xAxisData, seriesData) {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        grid: {
          left: 90,
          right: 60,
        },
        legend: {
          icon: "circle",
          right: "8%",
          right: "8%",
          top: "10%",
          textStyle: {
            color: "#AED6FF",
          },
        },
        xAxis: {
          data: xAxisData,
          axisLabel: {
            interval: 0,
            width: 24,
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
              fontSize: 12,
            },
          },
          axisTick: {
            show: false, // 取消刻度线显示
          },
        },
        yAxis: {
          name: "碳排:kt",
          nameTextStyle: {
            color: "rgb(167, 206, 247)",
          },

          // data: [0, 1, 2, 3, 4, 5, 6],
          axisLabel: {
            textStyle: {
              color: "#AED6FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
            // lineStyle:{
            //   type:'dashed',
            //   color: '#A0C6EF'
            // }
          },
        },
        series: [
          {
            name: "总碳排",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {offset: 0, color: "#1049A9"},
                {offset: 1, color: "#4E96D7"},
              ]),
              barBorderRadius: [30, 30, 0, 0],
            },
            data: seriesData,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("carbon")).setOption(option);
    },
  },
};
</script>
<style scoped>
#home {
  width: 100%;
  /* height: calc(100vh-121px); */
  min-width: 10.2rem;
  background: url(../../assets/images/home/background.jpg) no-repeat center;
  background-size: cover;
  font-size: 16px;
  position: relative;
}

.content {
  display: flex;
  justify-content: center;
}

.flex-center {
  height: calc(100vh - 130px);
  /* min-width: 100%; */
  /* overflow: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  display: flex;
}

.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: 0.1rem;
  /* margin-right: 8px; */
}

.nav ul:last-child {
  margin-right: 0.4rem;
  /* margin-right: 8px; */
}

.nav ul li {
  margin-bottom: 0.09rem;
  cursor: pointer;
}

.nav ul li:last-child {
  margin-bottom: 0;
}

/* 中等导航 */
.medium-nav {
  width: 2.69rem;
  height: 1.28rem;
  position: relative;
}

.medium-nav img {
  width: 2.69rem;
  height: 1.28rem;
}

.medium-text {
  position: absolute;
  left: 0.34rem;
  top: 0.56rem;
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
}

/* mini导航 */
.mini-nav {
  width: 1.31rem;
  height: 1.28rem;
  position: relative;
}

.mini-nav img {
  width: 1.31rem;
  height: 1.28rem;
}

.mini-text {
  position: absolute;
  left: 0.28rem;
  bottom: 0.28rem;
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
}

/* 小型导航 */
.small-nav {
  width: 2.67rem;
  height: 1.28rem;
  position: relative;
}

.small-nav img {
  width: 2.67rem;
  height: 1.28rem;
}

.small-text {
  position: absolute;
  left: 1.14rem;
  bottom: 0.53rem;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

/* 超大导航 */
.big-nav {
  width: 2.7rem;
  height: 2.64rem;
  position: relative;
}

.big-nav img {
  width: 2.7rem;
  height: 2.64rem;
}

.big-text {
  position: absolute;
  left: 0.99rem;
  bottom: 0.72rem;
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
}

/* disabled */
.disabled {
  cursor: not-allowed !important;
}

.first-nav {
  width: 2.69rem;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

/* 图标 */
.electricity-chart,
.carbon-chart {
  width: 6.78rem;
  height: 2.53rem;
  background: url(../../assets/images/home/chart-background.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.chart-title {
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  position: absolute;
  left: 0.75rem;
  top: 0.11rem;
}

.energy-operate {
  position: absolute;
  right: 0.33rem;
  top: 0.28rem;
  z-index: 100;
}

.all-energy {
  display: flex;
}

.all-energy .energy-type {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.02rem;
  display: inline-block;
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  text-align: center;
  line-height: 0.25rem;
  cursor: pointer;
}

.selected {
  background: #1852ad;
}

.disabled {
  cursor: not-allowed;
}

.carbon-operate {
  /* position: absolute;
  top: 0.15rem;
  right: 0.37rem; */
}

.carbon-operate ul {
  margin: 0;
  /* margin-left: 0.5rem; */
  background-color: rgb(9, 33, 89);
  padding: 10px 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.consume {
  margin-right: 0.18rem;
  width: 2rem;
  display: flex;
}

.consume div {
  height: 0.58rem;
  margin-left: 0.06rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.consume p {
  font-size: 0.2rem;
  font-family: DIN Next LT Pro;
  font-weight: 400;
  color: #166ebf;
  margin: 0;
}

.consume img {
  width: 0.57rem;
  height: 0.58rem;
}

.consume p::after {
  content: "(吨)";
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  margin-left: 5px;
}

.consume .tree::after {
  content: "(棵)";
}

.consume span {
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

#energy,
#carbon,#chartLotus,#chartNetwork,#chartStore,#chartSource {
  width: 7.6rem;
  height: 2.5rem;
  position: absolute;
  bottom: -0.3rem;
  left: -0.3rem;
}

.mascot {
  position: absolute;
  right: 0.79rem;
  bottom: 1rem;
}

.nav ul img {
  transition: transform 0.2s ease-in-out;
  transform: translateZ(0);
}

/* .control-panel:hover img,
.operation:hover img,
.examine:hover img,
.examine:hover img,
.aily-work:hover img,
.knowledge:hover img,
.monitor:hover img,
.secure:hover img,
.energy:hover img,
.analysis:hover img,
.plan:hover img,
.electricity:hover img,
.water:hover img,
.gas:hover img,
.heating:hover img {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
} */
.nav ul li div:not(.disabled):hover img {
  transform: scale(1.1);
  transition: transform 0.1s ease-in-out;
}

.homeIndex {
  width: 100%;
  /*min-width: 1920px;*/
  /* height: 100vh; */
}

.homeIndexF {
  width: 100%;
  overflow: auto;
}

.boxs {
  margin: 0.2rem;
  display: flex;
  padding-top: 0.6rem;
  justify-content: space-between;
}

.boxs .list {
  display: flex;
  width: 150px;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(9, 33, 89);
}

.boxs .list img {
  width: 56px;
  height: 56px;

}

.boxs .list .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxs .list .right p {
  margin: 5px;
}
</style>
